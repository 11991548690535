
//shop nav
$primaryColor:#2f628b;
$secondaryColor: #EFA832;//ec7a09
$redColor:#CB1E31;
.shopNav{
    box-sizing: border-box;
    margin:0px;
    padding: 0px;
    align-items: center;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    z-index:9;
    background-color: white;
}
.button
#shopNav{
    z-index: 9;
}

#donate{
    background-color: $primaryColor;
    color: #fff;
    margin-right: 20px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
    min-width: 60px;
    border-radius: 5px;
    font-size: 16px;
}
#donate3{
    //background-color: rgb(5, 42, 129);
    color: #fff;
    margin-right: 20px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 100px;
    cursor: pointer;
    min-width: 60px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #fff;
}
.shopNav0{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
#coName{
  font-size: 25px;
  font-weight: 800;  
  color:orange;
  text-decoration: none;
  margin-left: 10px;
}
.shopNav2{
    width: 100%;
    box-sizing: border-box;
    height: auto;
    min-width: 66px;
    height: 70px;
    padding-left: 40px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    padding-bottom: 0px;
    margin-bottom: 0px;
   
}
.contactsDiv{
    height: 30px;
    border-bottom: 1px solid #eee;
    background-color: #eee;
    display: flex;
    align-items: center;
    padding-left: 40px;
    justify-content: right;
    flex-wrap: wrap;
    padding-right: 30px;
 }
.contactsDiv p{
    font-size: 14px;
    //margin-right: 30px;
    //border-right: 1px solid #a7a7a7;
    //padding-right: 20px;
    color: $redColor;
    font-weight: 500;
    text-align: end;
    //color: #2135cd;
}
.mediaIcons{
    font-size: 14px;
    margin-right: 10px;
    color: $primaryColor;
    color: #00092d;
    color: $redColor;
    font-weight: 500;
}
.shopNav2 img{
    height:40px;
    margin-right: 5px;
   // display: none;
}
.shopScrolled{
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 9;
    height: 100px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    //opacity: 0.7;
   
}
 .navMain{
     display: flex;
     justify-content: space-between;
 }

 .btnsDiv{
     display: flex;
     align-items: center;
 }

.phoneP{
    color: $primaryColor;
    font-size: 17px;
    font-weight: 600;
    background-color: $redColor;
    color: white;
    padding: 5px 20px;
    cursor: pointer;
}
.emailP{
    font-size: 10px;
    font-weight: 400;
}
.btnsDiv2{
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
}
.btnsDiv2 p{
   color: $primaryColor;
   font-size: 18px;
   font-weight: 600;
   margin-top: -10px;
}
.shopNav3{
    margin-left: 50px;
    display: flex;
    //margin-right: 10%;
}
.optionsIcon{
 color: $primaryColor;
 margin-right: 5%;
 margin-left: 5px;
 display:none;
}
.optionsIcon:hover{
    cursor: pointer;
}
.ulLink{
    height: 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $primaryColor;
    cursor: pointer;
    margin-left: 30px;
    font-weight: 600;
    font-size: 18px;
    
}
.ulLink:hover{
    color:$secondaryColor;
    border-bottom: 1px solid $secondaryColor;
}


.shopNav5{
    display: none;
}
.ulLink5{
    height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $primaryColor;
    padding-left: 4%;
    font-size: 20px;
    font-weight: 600;
   
}
.optionsDiv{
    width:100%;
    display:none;
    flex-direction: column;
    background-color:white;
    margin-top: -70px;
    padding-top: 10px;
    z-index: 9999;
   padding-bottom: 20px;
}
.donBtn{
    padding: 3px 10px;
    border: none;
    color: white;
    background-color: $redColor;
    border-radius:5px;
    height: 30px;
    width: 80px;
    cursor: pointer;
}
.modal{
    position:fixed;
    top:0;bottom:0;left:0;right:0;
    background-color: #00000055;
    z-index: 9999;
    display:flex;
   align-items: center;
   justify-content: center;
}
.modalCont{
   height:300px;
   width:350px;
   box-sizing: border-box;
   background-color:#fff;
   border-radius: 10px;
   padding: 20px ;
 }
 .modalCont h5{
  font-size: 18px;
  margin-bottom: 5px;
  color: $primaryColor;
}
.contDiv{
    display:flex;
    margin-top: 10px;
    align-items: center;
  }
  .contDiv p{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $primaryColor;
  }
  .book{
    font-size: 16px;
    color: $primaryColor;
    
  }
  #close{
    width:100%;
    background-color:$secondaryColor;
    border-radius: 5px;
    margin-top: 20px;
    padding:5px 0px 5px 0px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
  }
.champBtn{
    padding: 3px 10px;
    border: none;
    color: white;
    background-color: $secondaryColor;
    border-radius:5px;
    height: 30px;
    width: 150px;
    margin-left: 15px;
    cursor: pointer;
}
.adminBtn{
    margin-left: 10px;
    padding: 0px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.donBtn:hover{
    background-color: #CB1E3199;
  }
.champBtn:hover{
    background-color: #EFA83299;
  }
  .adminBtn:hover{
    background-color: #eeeeee99;
  }
.logInModal{
    position: fixed;
    top:0;bottom:0;left:0;right:0;
    z-index: 6;
    background-color: #00000022;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logoCont{
    display: flex;
    align-items: center;
}
.logoP{
   font-size: 28px;
   font-weight: 800;
   font-family: "Rubik", sans-serif;
   color: #1d48c9;
   color: #000;
}
.logoP span{
    color: #e6a01e;
    color: #0536bd;
}
#logoP2{
    font-size: 10px;
    margin-top: -5px;
    letter-spacing: 17.8px;
    margin-left: 2px;
    color: $primaryColor;
    font-weight: 500;
   
}
.phoneIC{
    color: $secondaryColor;
    margin-right: 10px;
    font-size: 24px;
}
.email2P{
    margin-left: 22px;
    color: $primaryColor;
    font-size: 16px;
    font-weight: 500;
}

@media all and (max-width: 1300px) {
    .ulLink{
        margin-right: 0px;
    }
    .shopNav2{
        padding-left: 50px;
        padding-right: 0px;
    }
}
@media all and (max-width: 1200px) {
    .ulLink{
        margin-right: 30px;
    }
    .shopNav2{
        padding-left: 30px;
        padding-right: 0px;
    }
    .shopNav3{
        margin-left: 20px;
    }
    .ulLink{
        margin-right: 0px;
    }
   #donate{
    margin-right: 20px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 20px;
    font-size: 16px;
}
#donate3{font-size: 16px;}
}
@media all and (max-width: 1000px) {
    .ulLink{
        margin-left: 0px;
        font-size: 14px;
        padding-right: 8px;
        padding-left: 8px;
    }
}
@media all and (max-width: 850px) {
   
    #donate{
        margin-left: 0px;
    }
    .shopNav5{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding-left: 7%;
        padding-right: 7%;
    }
    .ulLink{
        margin-left: 0px;
        font-size: 14px;
        padding-right: 10px;
        padding-left: 0px;
    }
    
}
@media all and (max-width: 1100px) {
    #donate{
        margin-left:0px;
    }
   
    .shopNav3{
        display: none;
    }
    .optionsDiv{
        display:flex;
        position: absolute;
        margin-top: 0px;
    }
    .optionsIcon{
        display:flex;
       }
       .btnsDiv{
        display: none;
    }
    .donBtn{
        padding: 3px 10px;
        height: 30px;
        width: 170px;
        margin-left:4%;;
        font-size: 16px;
    }
    .champBtn{
        padding: 3px 10px;
        height: 30px;
        width: 170px;
        margin-left:4%;
        margin-top:10px;
        font-size: 16px;
    }
}
@media all and (max-width: 600px) {

    .shopNav5{
        padding-left: 5%;
        padding-right: 5%;
       
    }
   
    #coName{
        font-size: 20px;
        margin-left: 10px;
      }
    
}
@media all and (max-width: 500px) {
    .shopScrolled{
        width: 100%;
        position: fixed;
        background-color: white;
        z-index: 9;
        height: 115px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
        //opacity: 0.7;
       
    }
    .contactsDiv{
        justify-content: right;
        height: 45px;
        margin: 0px;
        padding: 2px 20px 0px 10px;
        //display: none;
    }
  .shopNav{
     border-bottom-width: 0px;
  }
  .shopNav2{
    padding-left: 10px;
    padding-right: 10px;
}
    .shopNav5{
        padding-left: 15px;
        padding-right: 15px;
       
    }
    .ulLink{
        font-size: 14px;
    }
}
@media all and (max-width: 420px) {
   
    .shopNav2{
        padding-right: 10px;
    }
    .shopNav2 img{
        height:30px;
        margin-left: 10px;
    }
    .logoP{
        font-size: 22px;
        margin-top: -5px;
     }
     #logoP2{
        font-size: 10px;
        margin-top: -5px;
        letter-spacing: 13px;
        margin-left: 2px;
        color: $primaryColor;
        font-weight: 500;
       
    }
    .shopNav5{
        padding-left: 15px;
        padding-right: 15px;
        //background-color: rgb(255, 196, 0);
    }
    .ulLink{
        font-size: 12px;
        margin-left: 5px;
        //padding-bottom: 0px;
    }
    .NavLogo img{
        //height: 40px;
        
    }
    .socialCont{
        display:none;
    }
   .shopNav5 .ulLink{
        height: auto;
        align-items: flex-start;
        margin-bottom: 10px;
        border-bottom: 2px solid #fff;
        padding-bottom: 3px;
        //background-color: #eb3e32;
    }
    .shopNav5 .ulLink:hover{
        border-bottom: 2px solid rgb(255, 196, 0);
    }
   .optionsIcon:hover{
       color: rgb(255, 196, 0);
   }
   .ulLink5{
   font-size: 20px;
   margin-top: 10px;
}

}
@media all and (max-width: 350px) {
    .modalCont{
        height:300px;
        width:320px;
    }
}
@media all and (max-width: 330px) {
    .modalCont{
        height:300px;
        width:300px;
    }
    .shopNav5{
        padding-left: 10px;
        padding-right: 10px;
       
    }
    .ulLink{
        font-size: 10px;
        margin-left: 5px;
    }

  
}

