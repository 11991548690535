$darkPrimary:#2f628b;
$primaryColor:#EFA832;
@mixin maxlinelength {
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1; /* number of lines to show */
     -webkit-box-orient: vertical;
  }
.boduTitleMainCont{
  min-height: 200px;
  width:100%;
  box-sizing: border-box;
  overflow: hidden;
 // background-color: red;
  margin-bottom:0px;
}
.titleDivCont{
  //height: 3vh;
  box-sizing: border-box;
  //min-height: 205px;
  width:100%;
   position: relative;
   overflow:hidden;
   outline-style: none;
   display: flex;
   padding: 10px 0px 0px 0px;
  
   //margin-left: 20px;
   //padding-right: 20px;
  // background-color:blue;
}
.testDiv{
    box-sizing: border-box;
    //height:202px;
    width:100%;
    //border: 1px solid #eee;
   
    //margin: 1px;
   // background-color: blue;
   // margin-right: 200px;
    //border: 1px solid #eee;
    //padding:2px;
   
   
}
.bodyLowerCard{
    width: 100%; 
    height: 220px;
    margin-bottom: 10px;
    display: flex;
    overflow: hidden;
    margin-left: 0px;

    display: flex;
    position: relative;
    align-items: center;
    //justify-content: center;
    
    
    //border-radius: 10px;
}
.prodImageContainer{
    height:100px;
    width: 100px;
    position: absolute;
    //border-radius: 100px;
}
.productsImage{
    height:100px;
    width: 100px;
    border-radius:100px;
    background-color: #fff;
    padding: 2px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    
}
.prodDetContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 90%;
    background-color: #fff;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    margin: 10px;
    border-radius: 10px;
    padding-left: 50px;
    margin-left: 50px;
    background-color: whitesmoke;
   
}
.headP{
    font-weight: 600;
    font-size: 30px;
    margin: 50px 0px 50px 0px;
    color: $darkPrimary; 
    border-bottom: 1px solid rgb(184, 182, 182);
    padding-bottom: 20px;
}
.title{
  font-size: 18px;
  padding-left: 10px;
  font-weight: 600;
  margin-top: 10px;
  color:$darkPrimary;
  padding-right: 30px;
  
}
.title span{
    color: $primaryColor;
}
.desc{
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    padding-right: 30px;
    margin-top: 5px;
    color: rgb(66, 66, 66);
    font-weight: 400;
    @include maxlinelength();
    -webkit-line-clamp: 5;
    line-height: 28px;
    
}
.quotA{
    color: $darkPrimary;
    margin-right: 5px;
}
.quotB{
    color: $darkPrimary;
    margin-left: 5px;
}
@media all and (max-width: 1200px) {
    .boduTitleMainCont{
        min-height: 200px;
        width:100%;
        box-sizing: border-box;
        overflow: hidden; 
      }
}
@media all and (max-width: 900px) {
    .boduTitleMainCont{
        min-height: 200px;
        width:100%;
        box-sizing: border-box;
        overflow: hidden;
      }
      .headP{
        font-size: 24px;
        margin: 30px 0px 50px 0px;
        padding-bottom: 20px;
    }
}
@media all and (max-width: 420px) {
   
    .titleDivCont{
       // height: 52vh;
       // min-height: 400px;
        width:100%;
       
         padding: 0px 0px 0px 0px;
         background-color:white;
      }
      .testDiv{
         // height:200px;
          width:99%;
          //margin: 2px;
          
      }
      .prodDetContainer{
        margin: 5px;
        border-radius: 10px;
        padding-left: 40px;
        margin-left: 40px;
    }
      .bodyLowerCard{
          width: 100%; 
          height: 200px;
          margin-bottom: 10px;
          margin-left: 0px;
      }
      .prodImageContainer{
          height:80px;
          width: 80px;
      }
      .productsImage{
        height:80px;
        width: 80px;
          
      }
      .boduTitleMainCont{
        min-height: 100px;
        width:100%;
        margin-top: 20px;
        margin-bottom:0px;
      }
      .title{
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        @include maxlinelength();
        -webkit-line-clamp: 1;
        
      }
      .desc{
          margin-left: 10px;
          padding-right: 10px;
          margin-top: 5px;
          @include maxlinelength();
          -webkit-line-clamp: 5;
          line-height: 20px;
          
      }
      .headP{
        font-size: 20px;
        margin: 10px 10px 30px 10px;
        padding-bottom: 20px;
    }
}

