$primaryColor: #ee9d2b;
.cont{
    display: flex;
    min-height: 500px;
    box-sizing: border-box;
    background-color:#fff;
    padding: 50px 100px;
    //width:100%;
  }
  .cont1{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content:center;
    flex:1;
    //width:50%;
    //height: 700px;
    padding:0px 80px 30px 100px;
    //background-color: aqua;
  }
  .cont2{
    box-sizing: border-box;
    flex:1;
    //width:50%;
    display: flex;
    //height: 700px;
    
    justify-content:center;
   //background-color: yellow;
    align-items: center;
     position: relative;
     overflow: hidden;
  }
  .theImage{
    width:50%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    //margin-top:20px;
    max-height: 550px;
  }
  .cont1 ul{
    margin-left: 20px;
    margin-top: 0px;
    color: rgb(97, 97, 97);
  }
  .cont1 ul li{
   margin-top: 10px;
  }
  #p1{
    font-weight:500;
    font-size: 20px;
    margin-top: 5px;
    //font-family: 'Lato', sans-serif;
    font-family: 'Poppins', sans-serif;
    color: $primaryColor;
    margin-bottom: 10px;
  }
  .p2{
    font-size: 24px;
    margin-top: 5px;
    font-family: 'Poppins', sans-serif;
    line-height: 35px;
    font-weight: 600;
  }
  .p3{
    //font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-top:20px;
    line-height: 25px;
    font-weight: 300;
    color: rgb(97, 97, 97);
  }
  .p4{
    font-size: 20px;
    margin-top:20px;
    font-weight: 500;
  }
  .p4B{
    font-size: 16px;
    margin-top:10px;
    font-weight:500;
    color: #4e4e4e;
  }
  @media all and (max-width: 800px) {
    .cont1{
      height: 600px;
      padding:0px 0px 30px 30px;
    }
    .cont2{
      height: 600px;
    }
  
    #p1{
      font-size: 20px;
      margin-top: 50px;
      margin-bottom:0px;
    }
  
    .p3{
      font-size: 18px;
      margin-top:15px;
      line-height: 25px;
      font-weight: 300;
    }
    .p4{
      font-size: 20px;
      margin-top:15px;
      font-weight: 500;
    }
    .cont{
      padding: 20px 30px;
      
    }
}
@media all and (max-width: 600px) {
  .cont{
    min-height: 500px;
    flex-direction: column;
  }
  .cont1{
    height: 600px;
    padding:0px 0px 30px 0px;
  }
}
@media all and (max-width: 430px) {
    .cont{
      min-height: 500px;
      padding: 20px 10px;
    
    }
    .theImage{
      max-height: 300px;
    }
    .cont1{
      height: auto;
      padding:0px 0px 40px 0px;
    }
    #p1{
      font-weight: 500;
      font-size: 20px;
      margin-top: 20px;
    }
    .p2{
      font-size: 22px;
      line-height: 32px;
    }
    .p3{
      font-size: 18px;
      margin-top:10px;
      line-height: 25px;
      font-weight: 300;
    }
    .p4{
      font-size: 20px;
      margin-top:10px;
      font-weight: 500;
    }
    .cont1 ul{
      margin-left: 15px;
      margin-top: 0px;
    }
}