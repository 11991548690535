$darkPrimary:#2f628b;
$primaryColor:#EFA832;
.container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
}
.servP1{
  text-align: center;
  font-size: 24px;
  color:#747a7d;
  font-weight: 500;
  margin: 0px 20px;
  }
  .servP2{
      font-weight: 700;
      text-align: center;  
      font-size: 34px;
      margin-bottom:30px;
      color:$darkPrimary;
  }
  .servP2B{
    color: $primaryColor;
  }
  .theCont1{
    border-top: 1px solid #eee;
    //margin-top: 50px;
    padding: 40px 70px;
    //background-color: white;
  }
  .theCont2{
    border-top: 1px solid #eee;
    margin-top: 0px;
    padding: 40px 70px;
    
  }
@media all and (max-width: 1100px) {
  .theCont1{
    padding: 30px  50px;
}
.theCont2{
  padding: 30px  50px;
}
}
@media all and (max-width: 700px) {
  .theCont1{
    padding: 30px  30px;
}
.theCont2{
  padding: 30px  30px;
}
}
@media all and (max-width: 700px) {
  .theCont1{
    padding: 30px 20px;
}
.theCont2{
  padding: 30px 20px;
}
}
@media all and (max-width: 430px) {
  .theCont1{
    padding: 30px  10px;
}
.theCont2{
  padding: 30px  10px;
}
}