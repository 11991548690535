

//footer
$darkPrimary:#2f628b;
$primaryColor:#EFA832; //#e20485;
$secondaryColor: #ee9d2b;
$hoverColor:#f7a64a;
$orangeColor:#EFA832;
$redColor:#ee9d2b;
.container{
    background-color:$darkPrimary;
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    //background-color: red;
}
.footerDiv{
    width:100%;
    padding-bottom: 38px;
    display: flex;
    padding-top: 30px;
    padding-left: 61px;
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 3;

 
}
.footerDivs{
    box-sizing: border-box;
    width: 25%;
    display: flex;
    padding-right: 38px;
    margin: 7px;
    flex-direction: column;
}
.footerDivs form{
    display: flex;
    flex-direction: column;
}
.footerDivs p{
    color: white;
}
.submitBtn{
    width: 130px;
    margin-top: 12px;
    height: 30px;
    background-color: $hoverColor;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
}
.locDivP1{
    margin-bottom: 10px;
    margin-top: 10px;
}
.locDivP2{
    margin-bottom: 10px;
    margin-left: 25px;
    margin-top: -10px;
    font-size: 18px;
}
.locIC{
    color: white;
    font-size: 20px;
    margin-right: 5px;
}
.contactsInput{
    margin-top: 10px;
    width:80%;
    height:35px;
    background-color:white;
    color:"$darkPrimary";
    outline: none;
    padding-right: 7px;
    padding-left: 7px;
    border: none;
    border: 1px solid #4d5772;
    
}
.locDiv{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.locDiv p{
    margin-top: -4px;
    font-size: 18px;
}
.lineDiv{
     background-color: $redColor;
     display: flex;
     justify-content: center;
     z-index: 5;
     min-height: 50px;
     color: white;
     font-size: 14px;
     align-items: center;
     padding: 5px 30px;
     font-size: 16px;
}
.lineDiv1{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ulFooter{
//margin-left: -15px;
margin-top: 3px;
display: flex;
flex-direction: column;
}
.ulFooter li{
    color: white; 
    margin-top: 5px;
}
 #FooterLi{
    list-style: none;
    font-size:18px;
    text-decoration: none; 
    margin-top: 10px;
    color: #fff;
}
.ulFooter li{
    margin-top: 11px;
}
#FooterLi:hover{
    cursor: pointer;
    color: $primaryColor;
}
.footerSendDiv{
    background-color:$redColor;
    width:130px;
    height:30px;
    margin-top:15px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 2px;
}
.footerSendDiv:hover{
  cursor: pointer;
  background-color: #cc5764;
}
.contactUs{
     font-size:22px;
     font-weight:500;
     color:#fff;
     margin-bottom: 5px;
}
.lineDiv1 p{
    cursor: pointer;
}
 .lineDivP1,.lineDivP2{
     border-right: 1px solid white;
     padding-right: 5px;
     margin-right: 5px;
    
 }


@media all and (max-width: 923px) {
   
    .footerDivs{
        width: 40%;
    }
    .contactUs{
         margin-top: 15px;
        
    }
    .contactsInput{
        width: 100%;
    }
    
    
}
@media all and (max-width: 900px) {
    .lineDiv{
        flex-direction: column;
        padding: 10px;
        min-height: 60px;
    }
}
@media all and (max-width: 615px) {
   
    .footerDiv{
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
       
        
    }
    .footerDivs{
        width: 80%;
       
    }
    
    .contactUs,.divCentreHead1{
        text-align: center;
    }
    .footerSendDiv{
        width: 100%;
       
    }
    .aboutUsDiv{
        align-self: center;
    }
    .contactsInput{
        width: 100%;
    }
    .locDiv{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;
    }
    
}

@media all and (max-width: 420px) {
    .lineDiv1{
        justify-content: center;
        margin-bottom: 10px;
    }
    .copyRightP{
        text-align: center;
    }
}

