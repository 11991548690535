
$primaryColor: #2f628b;
$secColor: #EFA832;
$theGrey1: #5F6162;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}

.bodySection3{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding-bottom: 38px;
    //background-color: #FBCD00;
}
.blogContainer{
    box-sizing: border-box;
     height: auto;
     overflow: hidden;
     padding-bottom: 0px;
     padding: 30px 100px 50px 100px;
     display: flex;
     flex-direction: column;
     position: relative;
     //background-color: whitesmoke;
 }
 .orderP{
     background-color: $secColor;
     //background-color: #ec7a09;
     color: white;
     margin: 0px 10px;
     text-align: center;
     padding: 5px 0px;
     cursor: pointer;
     margin-top: 10px;
     border-radius: 2px;
     width: 150px;
     text-align: center;
 }
.causedivzoom{
     height: auto;
     padding-bottom: 20px;
     width:23%;
    background-color:white;
   // margin:7px;
    justify-content:space-evenly;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    //border: 1px solid #eee;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 5px;

 
  }
  .causedivzoom img{
      box-sizing: border-box;
    width:97%;
     height:230px;
     min-height: 34vh;
     object-fit:contain;
     padding: 20px;
     border: 1px solid whitesmoke;
     margin: 4px;
     background-color: #eee;
     
  }
  .causedivzoom h2{
    padding: 0;
    margin: 0;
    font-size:20px;
    font-weight:500;
    padding-right:7px;
    padding-left:7px;
    padding-top: 3px;
    -webkit-line-clamp: 1;
    @include maxlinelength();
    //text-align: center;
    color:$primaryColor
   
  }
  .detDiv2{
      display: flex;
      align-items: center;
      //justify-content: center;
      height: 15px;
      margin-top: 2px;
      //background-color: #4b0161;
  }
  .causedivzoom h3{
    padding-right:7px;
    padding-left:7px;
    font-size: 16px;
    color: #839192;
    -webkit-line-clamp: 1;
    @include maxlinelength();
    //text-align: center;
    font-weight: 500;
    margin-top: 2px;
  
  }



  .causesDetCont{
    display:flex;
    flex-direction:column;
    align-items:center;
    height: 152px;
    background-color: #4b0161;
    width:100%;
    justify-content: center;
   
   
  }
  .causesDetCont h2{
    margin: 0em;
   font-size: 45px;
   color: white;
   padding-bottom: 15px;
   font-family: 'Teko', sans-serif;
   
  }

  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    //justify-content: center;
    
  }
  .detDiv{
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      align-items: center;
      //background-color: red;
  }
  .detDiv2 p{
      font-size: 12px;
      color:#858585;
      margin-left:2px;
  }
  .starIc{
    font-size: 15px;
    color:$primaryColor;
    margin-left:2px;
}
  .prodTitle{
      //color:#164da0;
      font-size: 16px;
      font-weight:400;
      color:$theGrey1;
     
  }
  .prodPrice{
    font-size:20px;
    font-weight: 600;
    color:$theGrey1;
    text-align: center;
  }
  .modal{
    position:fixed;
    top:0;bottom:0;left:0;right:0;
    background-color: #00000055;
    z-index: 9999;
    display:flex;
   align-items: center;
   justify-content: center;
}
.modalCont{
  height:300px;
  width:350px;
  box-sizing: border-box;
  background-color:#fff;
  border-radius: 10px;
  padding: 20px ;
}
 .modalCont h5{
  font-size: 18px;
  margin-bottom: 5px;
  color: $primaryColor;
}
.contDiv{
  display:flex;
  margin-top: 10px;
  align-items: center;
}
.contDiv p{
  margin-left: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $primaryColor;
}
.book{
  font-size: 16px;
  color: $primaryColor;
  
}
#close{
  width:100%;
  background-color:$secColor;
  border-radius: 5px;
  margin-top: 20px;
  padding:5px 0px 5px 0px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.email2P{
  margin-left: 22px;
  color: $primaryColor;
  font-size: 16px;
  font-weight: 500;
}
  @media all and (max-width: 1300px) {
    .causedivzoom{
      height: auto;
      padding-bottom: 20px;
      width:31%;
    }
  }
  @media all and (max-width: 900px) {
    .blogContainer{
       padding-bottom: 0px;
       padding: 30px 50px 50px 50px; 
   }
   .causedivzoom{
    width:47%;
  }
}
  @media all and (max-width: 950px) {
 
    .causesDetCont h2{
      font-size: 30px;
     }
     .img-hover-zoom{
      width:30%;
    }
    
   
    
}
@media all and (max-width: 760px) {
  .causedivzoom img{
     min-height:150px; 
     height:25vh;   
  }
  

}
@media all and (max-width: 600px) {
  
  .causesDetCont h2{
    font-size: 30px;
   }
   .causedivzoom{
    width:47%;
    margin-right: 5px;
    
  }
  .causedivzoom h2{
   font-size:25px;
    }
   
       .blogContainer{
        padding-bottom: 0px;
        padding: 30px 30px 50px 30px;  
    }
}
@media all and (max-width: 608px) {

 /* .causedivzoom img{
     height:228px;    
  }*/
}




@media all and (max-width: 532px) {
  .causesDetCont h2{
    font-size: 30px;
   }

  .causedivzoom{
    width:47%;
    margin-top: 0px;
  }
  .causedivzoom h2{
   font-size:20px;
    }
   
      
}

 @media all and (max-width: 456px) {
 
  .causedivzoom{
    width:46%;
  }
  .causedivzoom h2{
   font-size:25px;
    }
    
    
     .causesDetCont h2{
      font-size: 30px;
     }
}
   @media all and (max-width: 420px) {
     
  .causesItemsCont{
   margin-top: 25px;
  }
    .prodTitle{
        //color:#164da0;
        font-size: 15px;
        font-weight: 400;
    }
    .prodPrice{
        font-size: 16px;
    }
    .bodySection3{
     margin-top: 30px;
   }
    .causedivzoom{
      width:48%;
      margin-right: 3px;
      margin-left: 3px;
      margin-bottom:6px;
    }
    .causedivzoom h2{
     font-size:20px;
     padding: 0px 10px;
     margin-top: 10px;
      }
  
         .causedivzoom img{
          height:24vh; 
          min-height:150px;   
       }
       .causedivzoom h3{
        font-size:16px;
        padding: 0px 10px;
      
      }
      .blogContainer{
        padding-bottom: 0px;
        padding: 20px 10px 50px 10px;
    }
   }
   @media all and (max-width: 380px) {
    
  .causedivzoom h2{
    font-size:22px;
    padding: 0px 10px;
     }
     .causedivzoom h3{
      font-size:16px;
      padding: 0px 10px;
    }
   }
     @media all and (max-width: 342px) {
      .img-hover-zoom{
        width:100%;
        margin:3px;
       
       }
       .img-hover-zoom img{
        width:100%;
        height:266px;
        
       }
         .causedivzoom h2{
          font-size:20px;
           }
           .causesDetCont h2{
            font-size: 25px;
           }
      }
      @media all and (max-width: 350px) {
        .modalCont{
            height:300px;
            width:320px;
        }
    }
    @media all and (max-width: 330px) {
      .modalCont{
          height:300px;
          width:300px;
      }
    }