
//members
$darkPrimary:#2f628b;
$primaryColor:#EFA832;
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   /* number of lines to show */
   -webkit-box-orient: vertical;
}

.blogContainer{
   box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding-bottom: 0px;
    padding: 2px;
    //background-color: #ff8801;
   
}

.causedivzoom{
     height: auto;
     padding-bottom: 15px;
     width:32%;
    background-color:white;
    justify-content:space-between;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    //margin: 5px;
  }

  .causedivzoom img{
     width:100%;
     height:250px;
     min-height: 37vh;
     object-fit:cover;
    
  }
  .causedivzoom h1{
    padding: 0px 10px;
    margin: 0;
    font-size:18px;
    line-height:24px;
    font-weight:600;
    padding-top: 10px;
    -webkit-line-clamp: 2;
    @include maxlinelength();
    color: $darkPrimary;
   
  }
  .descP{
    padding: 0px 10px;
    font-size: 16px;
    line-height:21px;
    color: #686064;
    font-weight:400;
    margin-top: 5px;
    -webkit-line-clamp: 5;
    @include maxlinelength();
  
  }

  .p1{
    padding: 0px 10px;
    font-size: 14px;
    line-height: 20px;
    color: $primaryColor;
    font-weight:500;
    margin-top: 5px;
  }
  .p1B{
    padding: 0px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-weight:500;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .p2{
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    color:$primaryColor;
    //border: 1px solid #686064;
    width: 80px;
    //padding:2px 0px 2px 10px;
    margin-top:5px;
    margin-left: 10px;
    cursor: pointer;
    
  }

.ourTit{
  text-align: center;
  font-size: 14px;
  line-height:24px;
  color: $darkPrimary;
  font-weight: 600;
}
  .meetNews{
     text-align: center;
     font-size: 240px;
     line-height: 40px;
     font-weight: 800;
     margin-top: 15px;
     color: black;
  }

  .causesItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    justify-content: space-between;

  }
  @media all and (max-width: 900px) {
    .causedivzoom{
      width:48%;
    }
    
    .descP{
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }
  }
  @media all and (max-width: 700px) {
    .meetNews{
      font-size: 30px;
      line-height: 40px;
      margin-top: 15px;
     
   }
   
  }
  @media all and (max-width: 600px) {
    .causedivzoom img{
      width:100%;
      height:250px;
      min-height:31vh;
      object-fit:cover;
     
   }
  }
  @media all and (max-width: 420px) {
    .blogContainer{
       padding-bottom:0px;
       padding-top: 20px;
   }
    .causedivzoom{
      width:100%;
      margin-top: 10px;
    }

    .causedivzoom img{
      border-radius: 0px;
    }
    .causedivzoom h1{
      font-size:16px;
      padding-top: 10px;
      line-height: 18px;
    }
   .descP{
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }
    .meetNews{
      font-size: 25px;
      line-height: 40px;
      margin-top: 10px;
     
   }
  
  }
  @media all and (max-width: 350px) {
    .meetNews{
      font-size: 20px;
      line-height: 30px;
      margin-top: 10px;
     
   }
  }