
  //shops reel
  $primaryColor: #eb3e32;
  $secondaryColor: #FED800;
  .boduTitleMainCont{
    min-height:480px;
    //height: 70vh;
    width:100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    
  }
  .titleDivCont{
    min-height:480px;
    height: 70vh;
    width:100%;
    overflow:hidden;
  }
 #title2P{
  font-family: "Just Another Hand", serif;
  font-weight: 600;
  color: $secondaryColor;
  font-size: 30px;
 }
#titleH4{
  color: white;
  font-size: 60px;
  font-weight: 700;
   margin-top: 1.5%;
  //font-family: 'Lato', sans-serif;
  margin-left: 0px;
  margin-bottom: 0px;
  text-align: center;
  width: 50%;
 
  //font-style: italic;
}
#seeBtn{
  background-color: $primaryColor;
  border: none;
  height: 35px;
  width: 150px;
  border-radius: 30px;
  color: white;
  font-weight: 600;
  margin-top: 30px;
  cursor: pointer;
}
.detailsP{
  color: white;
  font-size: 40px;
  //background-color:red;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  //font-family: 'Lato', sans-serif;
  //font-style: italic;
  text-align: center;
}
.det2{
  margin-top: 40px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  //font-style: italic;
  background-color: #00000022;
  height: 45px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  //font-family: 'Rubik', sans-serif;
}
.det2B{
  margin-top: 40px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  //font-style: italic;
  background-color: #4b0161;
  height: 49px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}
.det3{
font-size: 12px;
color: #fff;
margin-top: 30px;
margin-bottom: 5px;
}
.emailDiv{
  background-color:rgb(32, 25, 124);
  display: flex;
  width:250px;
  justify-content:center;
  border-radius: 5px;
  height: 40px;
  align-items:center;
  margin-top: 5px;
}
#email1{
  box-sizing: border-box;
  padding-left: 10px;
  color:#000;
  font-weight: 400;
  border:none;
  border-style: none;
  outline-style: none;
  //background-color:red;
  overflow: hidden;
}
.email2{
  padding-right: 10px;
  height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content:center;
  font-family: 'Lato', sans-serif;
  color:white;
  text-decoration: none;
}
.email2:hover{
  cursor: pointer;
  
}

.aroowsDiv{
  position: absolute;
  margin-top: 500px;
  display: flex;
  margin-left: 50%;
  align-items: center;
  z-index: 999;
}
.titleCont{
  height:99%;
  position:absolute;
  display:flex;
  flex-direction:column;
  width:100%;
  background-color: #00000099;
}
.titleCont2{
  height:100%;
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  background-color: #00000099;
  
  //display: none;
}
.emDiv{
  position: absolute;
  bottom:0;left:0;
  z-index: 14;
  margin-bottom:100px;
  margin-left: 50px;
  display: none;
}
.theImage{
  width: 100%;
  height: 99.5%;
  object-fit: cover;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86))
}
.backFang{
  font-size: 20px;
  color: #fff;
  background-color:  #FB8F00;
  border-radius: 50px;
  padding:5px;
  
}
.frontFang{
  font-size: 20px;
  color: #fff;
  background-color: #FB8F00;
  border-radius: 50px;
  margin-left: 10px;
  padding:5px
}
.btnDiv{
  display: flex;
}
@media all and (max-width: 1200px) {
  #titleH4{
    margin-top: 2%;
    width: 60%;
  }
}
@media all and (max-width: 1000px) {
  #titleH4{
    margin-top: 2%;
    font-size: 60px;
  }
}
@media all and (max-width: 800px) {
  .boduTitleMainCont{
   // min-height:500px;
    height: 60vh;
    
  }
  .aroowsDiv{
    margin-top: 350px;
  }
  #titleH4{
    font-size: 50px;
    margin-top: 2.5%;
    width: 70%;
  }
  .detailsP{
    font-size: 40px; 
  }
  .titleDivCont{
    //min-height:500px;
    height: 60vh;
    
  }
  .emDiv{
    margin-bottom: 70px;
  }
}
@media all and (max-width: 615px) {

  #titleH4{
    font-size: 50px;
    margin-top:5%;
    font-weight: 700;
    width: 80%;
  }
  .detailsP{
    font-size: 40px;
  }
  .aroowsDiv{
  margin-top: 350px;
   
  }
  
}
  @media all and (max-width: 500px) {
    .boduTitleMainCont{
      min-height:400px;
      height: 40vh;
      box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.1);
      padding-bottom: 2px;
    }
    .titleDivCont{
      min-height:400px;
      height: 40vh;
    }
   
    .aroowsDiv{
 
      margin-top: 330px;
     
    }
    .emDiv{
      margin-bottom: 50px;
    }

    #titleH4{
      font-size: 40px;
      margin-top:5%;
      width: 90%;
    }
    .detailsP{
      font-size: 25px;
    }

}
@media all and (max-width: 430px) {
  .detailsP{
    font-size:24px;
    margin-top: 10px;
  }
  #titleH4{
    font-size:35px;
    margin-top: 3%;
    line-height: 45px;
    width: 90%;
  }
  .det3{
    font-size: 10px;
    }
    .emailDiv{
      width:170px;
      border-radius: 5px;
      height: 30px;
      margin-top: 5px;
    }
    #email1{
      padding-left: 10px;
      color:#000;
      font-weight: 400;
      font-size: 12px;
      border:none;
      border-style: none;
      outline-style: none;
    }
    .email2{
      padding-right: 0px;
      font-weight: 600;
      display: flex;
      font-size: 10px;
    }
    .titleCont2{
      padding: 0px 20px;

    }

    .det2{
      margin-top: 40px;
      margin-bottom: 0px;
      height: 40px;
      width: 130px;
      border: 2px solid #fff;
      font-size: 13px;
    }
    .det2B{
      margin-top: 40px;
      margin-bottom: 0px;
      height: 44px;
      width: 130px;
      font-size: 13px;
    }
   
}
  @media all and (max-width: 350px) {
    #titleH4{
      font-size: 30px;
      margin-top: 3%;
      line-height: 35px;
    }
    .detailsP{
      font-size: 20px;
    }
    .theImage{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .backFang{
      font-size: 20px;
     
    }
    .frontFang{
      font-size: 20px;
      
    }
}
@media all and (max-width: 300px) {
  .det2{
    margin-top: 40px;
    margin-bottom: 0px;
    height: 40px;
    width: 116px;
    border: 2px solid #fff;
    font-size: 12px;
  }
  .det2B{
    margin-top: 40px;
    margin-bottom: 0px;
    height: 43px;
    width: 120px;
    font-size: 12px;
  }
}